<template>
  <div ref="editorDiv">
    <ReportCreator ref="reportCreator"/>
    <b-modal ref="warningDialog" title="Report Editor Error"
      header-bg-variant="warning" header-text-variant="dark"
      body-bg-variant="dark" body-text-variant="light"
      footer-bg-variant="dark" footer-text-variant="light"
      content-class="shadow" @ok="handleWarningOK" ok-only >
      <span>{{warningMsg}}</span>
    </b-modal>
    <a ref="altReportLauncher" target="_blank"></a>
    <b-badge class="w-100 overflow-hidden" variant="info" show size="sm">
      <b-icon v-if="entry.stat" scale="1.0" icon="patch-exclamation-fill" class="text-danger mr-1"></b-icon>
      <b-icon v-if="entry.read" scale="1.0" icon="check-circle-fill"></b-icon>
      &nbsp;
      {{ title }}
    </b-badge>
    <b-navbar type="dark" variant="dark">
      <b-navbar-nav>
        <b-dropdown class="ml-1" :size="buttonSize" title="Reports for Patient" :variant="(studyList.length>1)?'info':'secondary'">
          <template #button-content>
            <b-icon icon="journals"></b-icon>
          </template>
          <b-dropdown-item v-for="s in studyList" :key="s.study_uid" :disabled="s.report_status=='---'" @click="secondaryStudy(s.study_uid)">
            <b-icon v-if="s.study_uid == entryToShow.study_uid" icon="toggle-on" variant="info"/>
            <b-icon v-else icon="toggle-off" variant="secondary"/>
            {{(s.study_date_time == null) ? '---' : new Date(s.study_date_time).toLocaleString(locale)}}
            [{{s.modality.trim()}}]
            {{(s.report_status=='---')?'No Report':s.report_status}}
          </b-dropdown-item>
        </b-dropdown>
      </b-navbar-nav>
      <!-- Right aligned nav items -->
      <b-navbar-nav v-if="reportBuffer != ''" class="ml-auto">
        <b-nav-text class="fixedHeight">&nbsp;</b-nav-text>
        <b-button-group :size="buttonSize">
          <b-button v-if="canDownload" @click="handleDownload('pdf')" variant="secondary" title="Download Report (PDF)" :disabled="reportBuffer == ''">
            <b-icon icon="file-ppt"></b-icon>
          </b-button>
          <b-button v-if="canDownload" @click="handleDownload('docx')" variant="secondary" title="Download Report (DOCX)" :disabled="reportBuffer == ''">
            <b-icon icon="file-word"></b-icon>
          </b-button>
          <b-button class="ml-2" v-if="canChangeTemplate" @click="handleChangeTemplate()" variant="warning" title="Change Template" :disabled="reportBuffer == ''">
            <b-icon icon="journal-code"></b-icon>
          </b-button>
          <b-button class="ml-2" @click="handleSave(reportStatusForSave, false)" :title="saveTitle" :disabled="!canSaveReports">
            <b-icon icon="journal-check"></b-icon>
          </b-button>
          <b-button variant="primary" class="ml-2" @click="handleSave(reportStatusForPreliminary, true)" :title="savePrelimTitle" :disabled="!canSignPrelim">
            <b-icon icon="journal-bookmark"></b-icon>
          </b-button>
          <b-button variant="success" class="ml-2" @click="handleSave(reportStatusForFinal, true)" :title="saveFinalTitle" :disabled="!canSignFinal">
            <b-icon icon="journal-bookmark-fill"></b-icon>
          </b-button>
          <b-button class="ml-2" @click="handleClose" title="Close Report">
            <b-icon icon="journal-x"></b-icon>
          </b-button>
        </b-button-group>
      </b-navbar-nav>
    </b-navbar>
    <b-alert v-if="reportError" class="mt-2" variant="warning" show>
      Editable report not ready.
      <b-button class="ml-2" variant="primary" @click="show()">Retry</b-button>
    </b-alert>
    <b-alert v-if="!reportError && (reportBuffer == '')" class="mt-2" variant="info" show>
      <b-spinner class="ml-2" label="Loading..." variant="info"/> Loading report...
    </b-alert>
    <div :width="editorWidth">
      <ejs-documenteditorcontainer ref="doceditcontainer"
        :height="editorHeight" 
        :serviceUrl='serviceUrl'
        :documentEditorSettings="documentEditorSettings"
        :enableLocalPaste='false'
        :enableToolbar="editorEnableToolbar"
        :enableSfdtExport='true'
        :enableWordExport='true'
        :enableTextExport='true'
        :showPropertiesPane='false'>
      </ejs-documenteditorcontainer>
    </div>
  </div>
</template>

<script>
import uuid from 'uuid'
import broadcast  from '../common/broadcast'
import permissions from '../common/permissions'
import webServices from '../common/webServices'
import workflow  from '../common/workflow'

// SyncFusion Document Editor
// https://ej2.syncfusion.com/vue/documentation/document-editor/getting-started/#run-the-documenteditor-application
//
import Vue from 'vue'
import ReportCreator from './ReportCreator.vue'
import { DocumentEditorContainerPlugin, Toolbar } from '@syncfusion/ej2-vue-documenteditor';
Vue.use(DocumentEditorContainerPlugin);

export default {
  name: 'reportEditor',
  components: {
    ReportCreator
  },
  data() {
    return {
      buttonSize: "sm",
      documentEditorSettings: { fontFamilies: [
        'Arial',
        'Calibri', 
        'Cambria',
        'Candara',
        'Courier New',
        'Georgia',
        'Impact',
        'Symbol',
        'Times New Roman',
        'Verdana',
        'Webdings'
      ]},
      editorEnableToolbar: false,
      editorHeight: '200px',
      editorWidth: '100px',
      entryToShow: webServices.getEmptyWorklistEntry(),
      reportBuffer: '',
      reportDirty: false,
      reportError: false,
      studyList: [],
      saveInProgress: false,
      warningMsg: ''
    };
  },
  props: {
    "closeOpensViewer": Boolean,
    "inReportWindow": Boolean
  },
  provide: {
    DocumentEditorContainer: [Toolbar]
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  computed: {
    entry() {
      const entry = this.$store.getters.worklistEntryForStudy(this.$store.state.activeStudyUid)
      if (entry != null) {
        return entry
      }
      else {
        return webServices.getEmptyWorklistEntry()
      }
    },
    locale() {
      return this.$store.state.locale
    },
    canChangeTemplate() {
      const draft = this.entryToShow.report_status.endsWith('DRAFT')
      return draft
    },
    canDownload() {
      return permissions.hasPermission(this.entryToShow.group, permissions.CAN_DOWNLOAD_REPORT)
    },
    canSaveReports() {
      return (!this.saveInProgress && (this.reportBuffer!='') && permissions.hasPermission(this.entryToShow.group, permissions.CAN_EDIT_REPORTS))
    },
    canSignPrelim() {
      const can_sign = permissions.hasPermission(this.entryToShow.group, permissions.CAN_SIGN_FINAL) || permissions.hasPermission(this.entryToShow.group, permissions.CAN_SIGN_PRELIM)
      const draft = this.entryToShow.report_status.endsWith('DRAFT') || this.entryToShow.report_status.endsWith('FINAL')
      return (!this.saveInProgress && (this.reportBuffer!='') && draft && can_sign)
    },
    canSignFinal() {
      return (!this.saveInProgress && (this.reportBuffer!='') && permissions.hasPermission(this.entryToShow.group, permissions.CAN_SIGN_FINAL))
    },
    reportId() {
      return this.$store.state.reportId
    },
    reportStatus() {
      return this.entryToShow.report_status
    },
    reportStatusForSave() {
      var status = this.reportStatus
      if ((this.reportStatus == 'FINAL') || (this.reportStatus == 'AMENDED FINAL')) {
        status = 'AMENDED DRAFT'
      }
      return status
    },
    reportStatusForPreliminary() {
      var status = 'PRELIMINARY'
      if ((this.reportStatus == 'FINAL') || this.reportStatus.startsWith('AMENDED ')) {
        status = 'AMENDED PRELIMINARY'
      }
      return status
    },
    reportStatusForFinal() {
      var status = 'FINAL'
      if ((this.reportStatus == 'FINAL') || this.reportStatus.startsWith('AMENDED ')) {
        status = 'AMENDED FINAL'
      }
      return status
    },
    saveTitle() {
      return 'Save ('+this.reportStatusForSave.replace('_', ' ')+')'
    },
    savePrelimTitle() {
      return 'Save and Sign ('+this.reportStatusForPreliminary.replace('_', ' ')+')'
    },
    saveFinalTitle() {
      return 'Save and Sign ('+this.reportStatusForFinal.replace('_', ' ')+')'
    },
    title() {
      return webServices.getTitleForEntry(this.entry)
    },
    serviceUrl() {
      this.$log.debug('serviceUrl='+this.$store.state.docServicesUrl)
      return this.$store.state.docServicesUrl
    }
  },
  watch: {
    entry(newVal/*, oldVal*/) {
      this.entryToShow = newVal
    }
  },
  methods: {
    show() {
      var obj = this.$refs.doceditcontainer.ej2Instances.documentEditor
      obj.openBlank()
      this.$log.debug("ReportEditor show reportId=["+this.reportId+"]")
      this.$store.commit('changeReportWindowsEditing', {
        'editing': true,
        'windowUid': this.$store.state.uid
      })
      this.reportBuffer = ''
      this.reportList = []
      this.studyList = []
      this.reportError = false
      this.saveInProgress = false
      this.editorEnableToolbar = false
      if ((this.reportId != '') && (this.entryToShow.study_uid != '')) {
        let format = 'sfdt'
        webServices.readReport(this.entryToShow.study_uid, this.reportId, this.entryToShow.group, format)
        .then(response => {
          if (response != null) { 
            this.$log.debug("readReport response for reportId=["+this.reportId+"]")
            var dataView = new DataView(response);
            var decoder = new TextDecoder('utf-8');
            this.reportBuffer = decoder.decode(dataView)
            this.$log.debug("readReport reportBuffer created for reportId=["+this.reportId+"]")
            obj.open(this.reportBuffer)
            this.$log.debug("readReport opened report in editor reportId=["+this.reportId+"]")
            obj.documentName = 'report_'+this.reportId
            this.$log.debug("Read report ["+obj.documentName+"]")
            this.handleResize()
          }
          else {
            this.$log.debug("Report null or empty.")
            this.reportError = true
          }
        })
        .catch(err => {
          this.$log.warn("Error fetching report, err: "+err.message)
          if (this.$store.state.activeComponent == 'ReportEditor') {
            let retryTimeout = 2000
            this.$log.warn("Error fetching report, will retry in "+retryTimeout/1000+" seconds, err=: "+err)
            var reportId = this.reportId
            setTimeout(() => {
              if (reportId == this.reportId) {
                this.show()
              }
            }, retryTimeout);
          }
          else {
            this.$log.debug("Skipping retry for report SFDT")
          }
        })

        webServices.readSinglePatientWorklist(this.entryToShow)
        .then(response => {
          // Make sure response includes current study.
          //
          for (var s = 0; s < response.length; s++) {
            if (response[s].study_uid == this.entryToShow.study_uid) {
              response.sort((a, b) => a.study_date_time - b.study_date_time)
              this.studyList = response
              break
            }
          }
        })
        .catch(err => {
          this.$log.error(`Unable to retrieve study list for current patient in report editor: ${err.message}`)
        })

      }
      this.handleResize()
    },
    displayToast(message, variant) {
      this.$bvToast.toast(message, {
        autoHideDelay: 5000,
        solid: true,
        title: 'INSPIRE PACS',
        variant: variant,
      })
    },
    handleChangeTemplate() {
      this.$store.commit('changeActiveComponent', 'ReportViewer')
      this.$refs.reportCreator.show(true)
    },
    handleClose() {
      var answer = window.confirm('Do you really want to leave while editing report?')
      if (answer) {
        if (this.closeOpensViewer) {
          this.$store.commit('changeActiveComponent', 'ReportViewer')
        }
        else {
          this.$store.commit('changeActiveStudyUid', '')
          this.$store.commit('changeActiveComponent', '')
        }
      }
    },
    handleDownload(format) {
      this.displayToast("Report download started...", 'info')
      webServices.readReport(this.entryToShow.study_uid, this.reportId, this.entryToShow.group, format)
      .then(response => {
        var patientName = this.entryToShow.patient_name_dcm.replaceAll('^', '_')
        var patientId = this.entryToShow.patient_id
        var studyDate = this.entryToShow.study_date_dcm
        const reportName = "report_"+patientName+"_"+patientId+"_"+studyDate+"."+format
        var mimeType = (format == 'docx') ? 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' : 'application/pdf'
        let reportBlob = new Blob([response], {type: mimeType})
        let reportUrl = URL.createObjectURL(reportBlob)
        this.$log.debug('Direct link to report: ' + reportUrl)
        this.$refs.altReportLauncher.href = reportUrl;
        this.$refs.altReportLauncher.setAttribute("download", reportName)
        this.$refs.altReportLauncher.click()
      })
      .catch(err => {
        this.$log.error(`Unable to download report: ${err.message}`)
        this.displayToast("Report download failed.", 'danger')
      })
    },
    handleSave(status, isSigned) {
      if (!this.saveInProgress) {
        // PUT report
        this.saveInProgress = true
        this.$log.debug('handleSave('+status+','+isSigned+')')
        var obj = this.$refs.doceditcontainer.ej2Instances.documentEditor
        // +TODO+ avoid round-trip request to convert sftp to docx
        obj.saveAsBlob('Docx')
        .then(docxBlob => {
          obj.saveAsBlob('Sfdt') 
          .then(sfdtBlob => {
            webServices.updateReport(sfdtBlob,
              docxBlob,
              this.entryToShow,
              this.reportId,
              status,
              isSigned)
            .then(response => {
              this.$log.debug(response)

              // Toggle read flag to true if not already.
              //
              if (isSigned && !this.entryToShow.read) {
                webServices.updateStudyRead(this.entryToShow, 'read')
                .then(response => {
                  this.$log.debug(response)
                  if (response.result == 'OK') {
                    this.entryToShow.read = response.read
                  }
                  else {
                    const toastMsg = response.result + " [" + webServices.getTitleForEntry(this.entryToShow) + "]"
                    this.displayToast(toastMsg, 'warning')
                  }
                })
                .catch(err => {
                  this.$log.error("Error updating read setting for study: "+err)
                })
              }
              
              // Change back to report viewer...
              //
              this.entryToShow.report_status = response.revision.status
              this.entryToShow.report_date_time = webServices.parseIsoTimestamp(response.revision.timestamp)
              this.entryToShow._rowVariant = webServices.reportStatusToVariant(response.revision.status, this.entryToShow.read, this.entryToShow.stat)
              this.$store.commit('changeActiveComponent', 'ReportViewer')

              // Update worklist entry...
              //
              webServices.readWorklist() // handle if running in worklist window
              broadcast.postMessage(broadcast.REFRESH_WORKLIST_MSG, this.$store.state.uid) // handle if in report window
            })
            .catch(err => {
              this.$log.error("Error updating report: "+err.message)
              this.warningMsg = 'Report not saved.'
              this.$refs.warningDialog.show()
            })
          })
          .catch(err => {
              this.$log.error("Error getting report as arraybuffer: "+err.message)
              this.warningMsg = 'Report not saved.'
              this.$refs.warningDialog.show()
          })
        })
        .catch(err => {
            this.$log.error("Error getting report as blob: "+err.message)
            this.warningMsg = 'Report not saved.'
            this.$refs.warningDialog.show()
        })
      }
      else {
        this.$log.warn("Request to save report ignored as save already in progress.")
      }
    },
    handleResize(/*event*/) {
      setTimeout(() => {
        try {
          this.editorHeight = "" + (window.innerHeight - 160) + "px"
          this.editorWidth = "" + this.$refs.editorDiv.clientWidth + "px"
          var obj = this.$refs.doceditcontainer.ej2Instances.documentEditor
          obj.resize()
          this.editorEnableToolbar = true
        }
        catch(err) {
          // Most likely component was destroyed before timeout
          this.$log.warn('handleResize: '+err.message)
        }
      }, 1000);
    },
    handleWarningOK() {
      this.saveInProgress = false
    },
    isDirty() {
      // +TODO+ Handle figuring out if any edits.
      //var obj = this.$refs.doceditcontainer.ej2Instances.documentEditor
      return true
    },
    secondaryStudy(studyUid) {
      if (studyUid == this.entryToShow.study_uid) {
        return;
      }
      let secondaryEntry = null
      for (var s = 0; s < this.studyList.length; s++) {
        if (this.studyList[s].study_uid == studyUid) {
          secondaryEntry = this.studyList[s]
          break;
        }
      }
      if (secondaryEntry !== null) {
        this.$log.debug(`Requesting secondary report window for studyUid=${studyUid}`)
        if (this.inReportWindow || this.$store.state.inViewerWindow) {
          // Let primary window handle opening report window.
          //
          broadcast.postMessage(broadcast.OPEN_REPORT_WINDOW_MSG, {
            'entry': secondaryEntry,
            'studyUid': studyUid,
            'windowUid': uuid.v4()
          })
        }
        else {
          this.$store.commit('addSecondaryWorklistEntry', secondaryEntry)
          workflow.openSecondaryReport(studyUid)
        }
      }
      else {
        this.$log.warn(`Secondary entry not found for studyUid=${studyUid}`)
      }
    },
  }
};
</script>
<style scoped>
@import '../../node_modules/@syncfusion/ej2-base/styles/bootstrap.css';
@import '../../node_modules/@syncfusion/ej2-buttons/styles/bootstrap.css';
@import '../../node_modules/@syncfusion/ej2-inputs/styles/bootstrap.css';
@import '../../node_modules/@syncfusion/ej2-popups/styles/bootstrap.css';
@import '../../node_modules/@syncfusion/ej2-lists/styles/bootstrap.css';
@import '../../node_modules/@syncfusion/ej2-navigations/styles/bootstrap.css';
@import '../../node_modules/@syncfusion/ej2-splitbuttons/styles/bootstrap.css';
@import '../../node_modules/@syncfusion/ej2-dropdowns/styles/bootstrap.css';
@import "../../node_modules/@syncfusion/ej2-vue-documenteditor/styles/bootstrap.css";
.editorDiv {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>